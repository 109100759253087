<template>
  <div class="home" style="margin-top:-15px">
    <header class="home--header py-5">
        <div class="home--header-overlay"></div>
      <div style="z-index:3;position:relative">
        <home-carousel />
      </div>
    </header>

    <section class="section--1">
        <div class="container position-relative">
          <div class="home_about_overlay"></div>
            <div class="row">
                <div class="col-12 col-md-10 offset-md-1 col-lg-8 offset-md-2">
                <div class="_flex mb-4 _center">
                    <img src="../assets/ico-01.png" alt="" class="home--crown">
                </div>
                    <h3 class="text-center gd-sage font-weight-bold" style="margin-bottom: 30px;"><strong>À propos de nous</strong><br>
                    </h3>
                    <p class="text-center" style="margin-bottom: 40px;">Depuis plus de 20 ans, Emery Mukendi Wafwana
                        &amp; Associates (EMW&amp;A) maintient sa position de cabinet international africain de premier
                        plan avec une pratique reconnue en droit des affaires et en droit minier. Nous offrons à nos
                        clients des services conformes aux meilleurs standards internationaux, avec une expertise
                        solidement établi dans les secteurs ci-après : ressources naturelles ; énergie et
                        infrastructures; investissement, commerce et industrie;
                        banques, finances et assurances ; famille et personnes.<br></p>
                    <div class="row" style="max-width: 700px;margin: 30px auto 0;">
                        <div class="col text-center">
                            <h1 style="font-weight: bold;margin-bottom: 0px;color:#A98A51">25+</h1>
                            <p>
                              <span v-if="english">Years of experience</span>
                              <span v-if="french">Années d'expérience</span>
                            </p>
                        </div>
                        <div class="col text-center">
                            <h1 style="font-weight: bold;margin-bottom: 0px;color:#A98A51">06</h1>
                            <p>
                              <span v-if="english">Offices</span>
                              <span v-if="french">Bureaux</span>
                            </p>
                        </div>
                        <div class="col text-center">
                            <h1 style="font-weight: bold;margin-bottom: 0px;color:#A98A51">26</h1>
                            <p>
                              <span v-if="english">Practice Jurisdictions</span>
                              <span v-if="french">Juridictions de pratique</span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <!-- Body 1 -->
    

    <section style="padding: 40px 0 40px;background-color: #fafafa;">
        <div class="container">
            <!-- <div class="separator--large"></div> -->
            <div>
                <h2 class="text-center gd-sage"><strong>Nos avocats</strong><br></h2>
                <p class="text-center" style="margin-bottom: 40px;">Nous aidons nos clients dans le monde entier à
                    résoudre tout problème juridique auquel une entreprise ou un homme d'affaires peut être
                    confronté.<br></p>
                <!--  -->
                <div class="experts" style="margin-top: 60px;">
                  <div v-if="newExperts.length">
                      <slick
                        ref="slick"
                        :options="slickExpertOptions"
                        @init="handleInit"
                      >
                        <div v-for="expert in newExperts" :key="expert.id">
                          <div class="card expert-card">
                              <router-link :to="`/profile/${expert.slug}`" class="mr-3">
                                    <img :data-src="expert._embedded['wp:featuredmedia']['0'].source_url" alt="" class="lazyload card-img-top w-100 d-block expert-img" v-if="expert._embedded['wp:featuredmedia']['0']" width="100%" loading="lazy">
                                    <div class="widget--expert-img-placeholder d-flex flex-row align-items-center justify-content-center pointer" v-else>
                                      <img src="../assets/logo-white.png" alt="">
                                    </div>
                                  </router-link>
                              <div class="card-body">
                                  <h5 class="card-title">
                                    <router-link :to="`/profile/${expert.slug}`" class="subheading medium pointer">
                                        {{expert.title.rendered}}
                                      </router-link>
                                  </h5>
                                  <div class="_flex mb-3">
                                            <i class="icon-pin mr-2" aria-hidden="true"></i>
                                            <span v-if="french">{{expert.acf.grade_fr}}</span>
                                            <span v-if="english">{{expert.acf.grade_en}}</span>
                                          </div>
                                  <ul class="list-unstyled">
                                      <li class="_flex body-1" v-if="expert.acf.office.length">
                                        <span class="icon-location-pin mr-1" aria-hidden="true"></span>
                                        <span v-for="office in expert.acf.office" :key="office.term_id" class="office--span">{{office.name}}</span>
                                        <br>
                                      </li>
                                      <li class="_flex body-1" v-if="expert.acf.office.length">
                                        <i class="icon-phone mr-2" aria-hidden="true"></i>
                                        <span v-for="(phone, i) in getExpertPhone(expert.acf.office)" :key="i" class="office--span">{{phone}}</span>
                                        <br></li>
                                      <li class="_flex body-1" v-if="expert.acf.email"><i class="icon-envelope-open mr-2" aria-hidden="true"></i>
                                            {{expert.acf.email}}<br></li>
                                  </ul>
                              </div>
                              <div class="card-footer">
                                <router-link :to="`/profile/${expert.slug}`" class="btn btn--home" style="font-size:13.5px">
                                          <span class="animated-underline ml-1 medium fg-primary">
                                            <span v-if="french">Voir profil</span>
                                            <span v-if="english">See profile</span>
                                          </span>
                                      </router-link>
                                </div>
                          </div>
                        </div>
                      </slick>
                </div>
                </div>
            </div>
        </div>
    </section>

    <!-- Body 2 -->
    <div class="body2">
        <div class="container py-10">
            <div class="row">
                <!-- Merites -->
                <div class="col-12 col-md-6 mb-5 mb-md-0">
                  <div class="">
                            <h5 class="bold mb-4 pb-3 _flex _between">
                              <span class="gd-sage" v-if="french">Nos bureaux</span>
                              <span class="gd-sage" v-if="english">Our offices</span>
                              <div @click="showMore = !showMore" class="c-pointer"><i class="far" :class="{'fa-angle-down': !showMore, 'fa-angle-up': showMore}"></i></div>
                            </h5>

                            <div class="row" v-show="showMore">
                              <div class="col-6 mb-3">
                                    <router-link to="/presence/kinshasa-rdc" class="_flex _between">
                                        <div>
                                            <h6 class="mb-0">Kinshasa</h6>
                                            <div class="" style="color:rgba(22, 40, 74, .7)">
                                              <span v-if="english">DRC</span>
                                              <span v-if="french">RDC</span>
                                            </div>
                                        </div>
                                        <a class="c-pointer">
                                            <svg aria-hidden="true" focusable="false" width="16px" height="16px" fill="currentColor" viewBox="0 0 36 36" class="widget--hover-brand-color widget--arrow">
                                              <path fill-rule="evenodd" clip-rule="evenodd"
                                                  d="M18.2354 2H24.2711L36 18L24.2711 34H18.2354L28.1237 20.56H0V15.44H28.1237L18.2354 2Z" fill="currentColor">
                                              </path>
                                          </svg>
                                        </a>
                                    </router-link>
                                </div>
                                <div class="col-6 mb-3">
                                    <router-link to="/presence/johannesbourg-rsa" class="_flex _between">
                                        <div>
                                            <h6 class="mb-0">Johannesburg</h6>
                                            <div class="" style="color:rgba(22, 40, 74, .7)">
                                              <span v-if="english">South Africa</span>
                                              <span v-if="french">Afrique du sud</span>
                                            </div>
                                        </div>
                                        <a class="c-pointer">
                                            <svg aria-hidden="true" focusable="false" width="16px" height="16px" fill="currentColor" viewBox="0 0 36 36" class="widget--hover-brand-color widget--arrow">
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                d="M18.2354 2H24.2711L36 18L24.2711 34H18.2354L28.1237 20.56H0V15.44H28.1237L18.2354 2Z" fill="currentColor">
                                            </path>
                                        </svg>
                                        </a>
                                    </router-link>
                                </div>
                                
                                <div class="col-6 mb-3">
                                    <router-link to="/presence/brazzaville-congo" class="_flex _between">
                                        <div>
                                            <h6 class="mb-0">Brazzaville</h6>
                                            <div class="" style="color:rgba(22, 40, 74, .7)">Congo Brazza</div>
                                        </div>
                                        <a class="c-pointer">
                                            <svg aria-hidden="true" focusable="false" width="16px" height="16px" fill="currentColor" viewBox="0 0 36 36" class="widget--hover-brand-color widget--arrow">
                                              <path fill-rule="evenodd" clip-rule="evenodd"
                                                  d="M18.2354 2H24.2711L36 18L24.2711 34H18.2354L28.1237 20.56H0V15.44H28.1237L18.2354 2Z" fill="currentColor">
                                              </path>
                                          </svg>
                                        </a>
                                    </router-link>
                                </div>
                                <div class="col-6 mb-3">
                                    <router-link to="/presence/lubumbashi-rdc" class="_flex _between">
                                        <div>
                                            <h6 class="mb-0">Lubumbashi</h6>
                                            <div class="" style="color:rgba(22, 40, 74, .7)">
                                              <span v-if="english">DRC</span>
                                              <span v-if="french">RDC</span>
                                            </div>
                                        </div>
                                        <a class="c-pointer">
                                            <svg aria-hidden="true" focusable="false" width="16px" height="16px" fill="currentColor" viewBox="0 0 36 36" class="widget--hover-brand-color widget--arrow">
                                              <path fill-rule="evenodd" clip-rule="evenodd"
                                                  d="M18.2354 2H24.2711L36 18L24.2711 34H18.2354L28.1237 20.56H0V15.44H28.1237L18.2354 2Z" fill="currentColor">
                                              </path>
                                          </svg>
                                        </a>
                                    </router-link>
                                </div>
                                <div class="col-6 mb-3">
                                    <router-link to="/presence/matadi-rdc" class="_flex _between">
                                        <div>
                                            <h6 class="mb-0">Matadi</h6>
                                            <div class="" style="color:rgba(22, 40, 74, .7)">
                                              <span v-if="english">DRC</span>
                                              <span v-if="french">RDC</span>
                                            </div>
                                        </div>
                                        <a class="c-pointer">
                                            <svg aria-hidden="true" focusable="false" width="16px" height="16px" fill="currentColor" viewBox="0 0 36 36" class="widget--hover-brand-color widget--arrow">
                                              <path fill-rule="evenodd" clip-rule="evenodd"
                                                  d="M18.2354 2H24.2711L36 18L24.2711 34H18.2354L28.1237 20.56H0V15.44H28.1237L18.2354 2Z" fill="currentColor">
                                              </path>
                                          </svg>
                                        </a>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                </div>
                <div class="col-12 col-md-5 offset-md-1">
                    <div class="_flex">
                        <div class="mr-5 mr-10-md mr-15-lg">
                            <div>
                                <h5 class="gd-sage medium">
                                  <span v-if="english">Member of the alliance</span>
                                  <span v-if="french">Membre de l'alliance</span>
                                </h5>
                                <a href="https://www.lexafrica.com" target="_blank" class="mt-3">
                                    <img data-src="http://www.cabemery.org/wp-content/uploads/LEXAFRICA-LOGO-MEMBER.png" alt="" class="img_merite lazyload">
                                </a>
                            </div>
                            <!--  -->
                            <div class="mt-3">
                                <h5 class="gd-sage medium">
                                  <span v-if="english">Ranked in band 1 by</span>
                                  <span v-if="french">Référencé Band 1 sur</span>
                                </h5>
                                <a href="https://chambers.com/profile/department/114685?publicationTypeId=2&practiceAreaId=852&locationId=63&subsectionTypeId=1" target="_blank" class="mt-3">
                                    <img data-src="https://assets.chambers.com/logo/chambers_blue.svg?ts=20180821" alt="" class="img_merite lazyload">
                                </a>
                            </div>
                        </div>
                        <!-- Sponsors -->
                        <div>
                            <h5 class="gd-sage medium">
                              <span v-if="english">Sponsor of</span>
                              <span v-if="french">Sponsor de</span>
                            </h5>
                            <div class="mt-3">
                                <a href="https://www.juridocs.org" target="_blank">
                                  <img src="../assets/juridocs.png" alt="" class="img_sponsor mb-2">
                                </a>
                                <a href="https://www.juriafrique.com" target="_blank">
                                  <img src="../assets/juriafrique.png" alt="" class="img_sponsor mb-2">
                                </a>
                                <a href="https://www.afrilex.com" target="_blank">
                                  <img src="../assets/afrilex.png" alt="" class="img_sponsor">
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Experts -->
            </div>
        </div>
    </div>

  </div>
</template>

<script>
// @ is an alias to /src
import {mapGetters} from 'vuex'
import HomeCarousel from '@/components/HomeCarousel.vue'
import Alaune from '@/components/Alaune.vue'
import { languageMixin } from '../mixins/language'


export default {
  name: 'home',
  mixins: [languageMixin],
  components: {
    HomeCarousel,
    Alaune
  },
  data: () => ({
    showMore: true,
    slickOptions: {
        dots: false,
        infinite: true,
        speed: 400,
        slidesToShow: 1,
        variableWidth: true,
        autoplay: true,
        autoplaySpeed: 4000,
    },
    slickExpertOptions: {
      dots: false,
      infinite: true,
      speed: 300,
      slidesToShow: 4,
      slidesToScroll: 2,
      adaptiveHeight: true,
    autoplay: true,
      responsive: [
          {
              breakpoint: 1024,
              settings: {
                  slidesToShow: 3,
                  slidesToScroll: 2,
                  infinite: true,
                  dots: true
              }
          },
          {
              breakpoint: 767,
              settings: {
                  slidesToShow: 2,
                  slidesToScroll: 1
              }
          },
          {
              breakpoint: 600,
              settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1
              }
          }
          // You can unslick at a given breakpoint now by adding:
          // settings: "unslick"
          // instead of a settings object
      ]
    }
  }),
  computed: {
    ...mapGetters(['newExperts', 'presences']),
    alaune() {
      return this.$store.getters.alaune.slice(0,3);
    }
  },
  methods: {
    // startSliding() {
    //   return  $('.experts-sliding').slick({
    //     dots: false,
    //     infinite: true,
    //     speed: 400,
    //     slidesToShow: 1,
    //     variableWidth: true,
    //     autoplay: true,
    //     autoplaySpeed: 4000,
    //   });
    // },
    handleReInit() {
      // Helpful if you have to deal with v-for to update dynamic lists
      this.$nextTick(() => {
        this.$refs.slick.reSlick();
      });
    },
    handleInit(event, slick) {
            console.log('handleInit', event, slick);
        },
  },
  // updated() {
  //     this.handleReInit()
  // }
}
</script>