<template>
  <div>
    <!-- Navigation 1 -->
    <nav class="border-bottom py-2 position-relative z-10">
      <div class="_container _flex _between">
        <div class="_flex">
          <a
            data-toggle="tooltip"
            data-placement="bottom"
            title="Français"
            @click="onChangeLanguage('fr')"
            class="_flex change_lang medium border-right pr-3 mr-3"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              width="18px"
              height="18px"
              class="mr-2"
            >
              <path
                d="M21 4H11l-1-3H3c-1.1 0-2 .9-2 2v15c0 1.1.9 2 2 2h8l1 3h9c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zM7 16c-2.76 0-5-2.24-5-5s2.24-5 5-5c1.35 0 2.48.5 3.35 1.3L9.03 8.57c-.38-.36-1.04-.78-2.03-.78-1.74 0-3.15 1.44-3.15 3.21S5.26 14.21 7 14.21c2.01 0 2.84-1.44 2.92-2.41H7v-1.71h4.68c.07.31.12.61.12 1.02C11.8 13.97 9.89 16 7 16zm6.17-5.42h3.7c-.43 1.25-1.11 2.43-2.05 3.47-.31-.35-.6-.72-.86-1.1l-.79-2.37zm8.33 9.92c0 .55-.45 1-1 1H14l2-2.5-1.04-3.1 3.1 3.1.92-.92-3.3-3.25.02-.02c1.13-1.25 1.93-2.69 2.4-4.22H20v-1.3h-4.53V8h-1.29v1.29h-1.44L11.46 5.5h9.04c.55 0 1 .45 1 1v14z"
              />
              <path d="M0 0h24v24H0zm0 0h24v24H0z" fill="none" />
            </svg>
            FR
          </a>
          <a
            data-toggle="tooltip"
            data-placement="bottom"
            title="English"
            @click="onChangeLanguage('en')"
            class="_flex change_lang medium  border-right pr-3 mr-3"
          >
            EN
          </a>
          <!-- <router-link tag="a" to="/newsletter" class="_navigation d-none d-lg-flex flex-row align-items-center _center medium border-right pr-4">
                        <i class="fal fa-envelope-open mr-2"></i> 
                        <span v-if="english">Subscribe to the newsletter</span>
                        <span v-if="french">S'abonner à la newsletter</span>
                    </router-link> -->
          <div class="_flex _socials ml-1">
            <!-- Facebook -->
            <a
              href="https://www.facebook.com/cabemery"
              target="_blank"
              class="fg-facebook"
              ><i class="icon-social-facebook"></i
            ></a>
            <!-- Linkedin -->
            <a
              href="https://www.linkedin.com/company/1585003"
              target="_blank"
              class="ml-3 ml-4-md fg-linkedin"
              ><i class="icon-social-linkedin"></i
            ></a>
            <!-- Twitter -->
            <a
              href="https://twitter.com/EMWAssociates"
              target="_blank"
              class="ml-3 ml-4-md fg-twitter"
              ><i class="icon-social-twitter"></i
            ></a>
            <!-- Youtub -->
            <a
              href="https://www.youtube.com/channel/UCKImCP7wnXvQDijIGN4VNNQ"
              target="_blank"
              class="ml-3 ml-4-md fg-youtube"
              ><i class="icon-social-youtube"></i
            ></a>
          </div>
        </div>
        <div
          class="_flex mt-2 mt-0-md justify-content-center justify-content-md-end"
        >
          <span class="medium mr-1" v-if="french">Ouvert:</span>
          <span class="medium mr-1" v-if="english">Open:</span>
          <span v-if="french">Lundi - Vendredi</span
          ><span v-if="english">Monday - Friday</span>
          <span class="mx-1 widget--title">|</span>
          <i class="icon-clock bold mr-1" aria-hidden="true"></i> 09H00 - 18H00
          <span class="mx-1 widget--title">|</span>
          <i class="icon-earphones-alt bold mr-1" aria-hidden="true"></i>
          +(243) 81-54-88-334
        </div>
      </div>
    </nav>

    <!-- Navigation 2 -->
    <nav
      style="z-index:10000!important;box-shadow: 0 5px 20px 0 rgba(11,7,110,.04)!important;"
      id="navbar"
    >
      <div class="_flex _between py-2 _container position-relative z-10">
        <router-link tag="a" to="/">
          <img src="../assets/logo.png" alt="" class="_logo_main" />
        </router-link>
        <div class="d-none d-lg-flex flex-row align-items-center _menu">
          <!-- Accueil -->
          <router-link to="/" class="animated-underline">
            <span v-if="french">Accueil</span>
            <span v-if="english">Home</span>
          </router-link>
          <!-- About -->
          <div href="" class="dropdown-mini">
            <a class="dropbtn-mini _flex">
              <span v-if="english">About</span>
              <span v-if="french">À propos</span>
              <i class="fal fa-angle-down ml-2"></i
            ></a>
            <div class="dropdown-content-mini dropdown-menu-left">
              <router-link
                :to="`/about/${option.slug}`"
                v-for="option in about"
                :key="option.id"
              >
                <span v-if="english">{{ option.acf.title_en }}</span>
                <span v-if="french">{{ option.acf.title_fr }}</span>
              </router-link>
            </div>
          </div>
          <div href="" class="dropdown-mini">
            <a class="dropbtn-mini _flex">
              <span v-if="english">Practice areas</span>
              <span v-if="french">Secteurs</span>
              <i class="fal fa-angle-down ml-2"></i
            ></a>
            <div class="dropdown-content-mini dropdown-menu-left">
              <router-link
                :to="`/practice-areas/${practice.slug}`"
                v-for="practice in practiceAreas"
                :key="practice.id"
              >
                <span v-if="english">{{ practice.acf.titre_en }}</span>
                <span v-if="french">{{ practice.acf.titre_fr }}</span>
              </router-link>
            </div>
          </div>
          <div href="" class="dropdown-mini">
            <a class="dropbtn-mini _flex">
              <span v-if="english">Services</span>
              <span v-if="french">Services</span>
              <i class="fal fa-angle-down ml-2"></i
            ></a>
            <div class="dropdown-content-mini dropdown-menu-left">
              <router-link
                :to="`/services/${service.slug}`"
                v-for="service in services"
                :key="service.id"
              >
                <span v-if="english">{{ service.acf.titre_en }}</span>
                <span v-if="french">{{ service.acf.titre_fr }}</span>
              </router-link>
            </div>
          </div>
          <div href="" class="dropdown-mini">
            <a class="dropbtn-mini _flex">
              <span v-if="english">Presence</span>
              <span v-if="french">Présence</span>
              <i class="fal fa-angle-down ml-2"></i
            ></a>
            <div class="dropdown-content-mini dropdown-menu-left">
              <router-link
                :to="`/presence/${presence.slug}`"
                v-for="presence in presences"
                :key="presence.id"
              >
                <span v-if="english">{{ presence.title.rendered }}</span>
                <span v-if="french">{{ presence.title.rendered }}</span>
              </router-link>
            </div>
          </div>
          <div href="" class="dropdown-mini">
            <a class="dropbtn-mini _flex">
              <span v-if="english">News & Publications</span>
              <span v-if="french">Actualités & Publications</span>
              <i class="fal fa-angle-down ml-2"></i>
            </a>
            <div class="dropdown-content-mini dropdown-menu-left">
              <router-link tag="a" to="/news">
                <span v-if="english">News</span>
                <span v-if="french">Actualités</span>
              </router-link>
              <router-link tag="a" to="/alerts">
                <span v-if="english">Alerts</span>
                <span v-if="french">Alertes</span>
              </router-link>
              <router-link tag="a" to="/publications">
                <span v-if="english">Publications</span>
                <span v-if="french">Publications</span>
              </router-link>
              <router-link tag="a" to="/events">
                <span v-if="english">Events</span>
                <span v-if="french">Evénements</span>
              </router-link>
              <router-link tag="a" to="/webinars">
                <span v-if="english">Webinars</span>
                <span v-if="french">Webinaires</span>
              </router-link>
            </div>
          </div>
          <router-link tag="a" to="/multimedia" class="animated-underline">
            <span v-if="english">Multimedia</span>
            <span v-if="french">Multimédia</span>
          </router-link>
          <router-link tag="a" to="/contact-us" class="animated-underline">
            <span v-if="english">Contact</span>
            <span v-if="french">Contact</span>
          </router-link>
        </div>
        <div class="headline d-block d-md-none" @click="openNav">
          <i class="fal fa-bars"></i>
        </div>
      </div>
    </nav>

    <!-- Sidenav -->
    <div class="sidenav" id="mySidenav">
      <a class="closebtn c-pointer text-white" @click="closeNav">
        <div class="hamburger__close"></div>
      </a>
      <router-link to="/">
        <span v-if="french">Accueil</span>
        <span v-if="english">Home</span>
      </router-link>
      <!-- About -->
      <a>
        <span v-if="english">About</span>
        <span v-if="french">À propos</span>
      </a>
      <div class="_submenu">
        <a
          @click="goto({ url: '/about', data: option })"
          v-for="option in about"
          :key="option.id"
        >
          <span v-if="english">{{ option.title_en }}</span>
          <span v-if="french">{{ option.title_fr }}</span>
        </a>
      </div>
      <!-- Practice areas -->
      <a>
        <span v-if="english">Practice areas</span>
        <span v-if="french">Secteurs</span>
      </a>
      <div class="_submenu">
        <a
          @click="goto({ url: '/practice-areas', data: practice })"
          v-for="practice in practiceAreas"
          :key="practice.id"
        >
          <span v-if="english">{{ practice.title_en }}</span>
          <span v-if="french">{{ practice.title_fr }}</span>
        </a>
      </div>
      <!-- Services -->
      <a>
        <span v-if="english">Services</span>
        <span v-if="french">Services</span>
      </a>
      <div class="_submenu">
        <a
          @click="goto({ url: '/services', data: service })"
          v-for="service in services"
          :key="service.id"
        >
          <span v-if="english">{{ service.title_en }}</span>
          <span v-if="french">{{ service.title_fr }}</span>
        </a>
      </div>
      <!-- Presence -->
      <a>
        <span v-if="english">Presence</span>
        <span v-if="french">Présence</span>
      </a>
      <div class="_submenu">
        <a
          @click="goto({ url: '/presence', data: presence })"
          v-for="presence in presences"
          :key="presence.id"
        >
          <span v-if="english">{{ presence.title_en }}</span>
          <span v-if="french">{{ presence.title_fr }}</span>
        </a>
      </div>
      <!-- Presence -->
      <a>
        <span v-if="english">News & Publications</span>
        <span v-if="french">Actualités & Publications</span>
      </a>
      <div class="_submenu">
        <router-link tag="a" to="/news">
          <span v-if="english">News</span>
          <span v-if="french">Actualités</span>
        </router-link>
        <router-link tag="a" to="/alerts">
          <span v-if="english">Alerts</span>
          <span v-if="french">Alertes</span>
        </router-link>
        <router-link tag="a" to="/publications">
          <span v-if="english">Publications</span>
          <span v-if="french">Publications</span>
        </router-link>
        <router-link tag="a" to="/events">
          <span v-if="english">Events</span>
          <span v-if="french">Evénements</span>
        </router-link>
        <router-link tag="a" to="/webinars">
          <span v-if="english">Webinars</span>
          <span v-if="french">Webinaires</span>
        </router-link>
      </div>
      <!--  -->
      <router-link tag="a" to="/contact-us">
        <span v-if="english">Contact</span>
        <span v-if="french">Contact</span>
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { languageMixin } from "../mixins/language";

export default {
  mixins: [languageMixin],
  data: () => ({
    selectedLanguage: "en",
    sidebar: false,
  }),
  computed: {
    ...mapGetters([
      "loading",
      "about",
      "services",
      "practiceAreas",
      "presences",
      "newsAndPublications",
    ]),
  },
  watch: {
    language(value) {
      this.selectedLanguage = value;
    },
  },
  methods: {
    openNav() {
      document.getElementById("mySidenav").style.width = "300px";
    },
    closeNav() {
      document.getElementById("mySidenav").style.width = "0";
    },
    scrollNavLeft() {
      var elmnt = document.getElementById("widget--navbar");
      elmnt.scrollLeft += 240;
    },
    goto(value) {
      // console.log(value);
      this.$router.push({
        // path: this.english ? value.url + value.data.url_en : value.url + value.data.url_fr,
        path: value.url,
        query: { id: value.data.id },
      });
    },
    onToggleSidebar() {
      return (this.sidebar = !this.sidebar);
    },
    onChangeLanguage(value) {
      this.$store.dispatch("changeLanguage", value);
    },
  },
  created() {
    this.selectedLanguage = this.language;
    if (!this.about.length) this.$store.dispatch("getAbout");
    if (!this.services.length) this.$store.dispatch("getServices");
    if (!this.practiceAreas.length) this.$store.dispatch("getPracticeAreas");
    if (!this.presences.length) this.$store.dispatch("getPresences");
    if (!this.newsAndPublications.length)
      this.$store.dispatch("getNewsAndPublications");
  },
};
</script>
