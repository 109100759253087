<template lang="html">
  <div class="mt-4 mt-md-0">
    <div class="row overflow-x-hidden">
      <!--  -->
      <div class="col-12 col-md-6 col-lg-12 pr-md-3 p-0 pr-lg-0 mb-4">
        <div class="secondary--background widget--home-experts-two p-3 pl-4">
          <h5 class="mt-2 mb-4 text-uppercase gd-sage">
            <strong v-if="french">NOS EXPERTS</strong>
            <strong v-if="english">OUR EXPERTS</strong>
          </h5>
          <div class="widget--card" v-if="newExperts.length">
            <slick ref="slick" :options="slickExpertOptions">
              <div v-for="expert in newExperts" :key="expert.id">
                <div class="row no-gutters w-100">
                  <router-link
                    :to="`/profile/${expert.slug}`"
                    class="col-5 pr-2"
                  >
                    <img
                      :data-src="
                        expert._embedded['wp:featuredmedia']['0'].source_url
                      "
                      alt=""
                      class="lazyload w3-round widget--expert-img pointer"
                      v-if="expert._embedded['wp:featuredmedia']['0']"
                    />
                    <div
                      class="widget--expert-img-placeholder d-flex flex-row align-items-center justify-content-center pointer"
                      v-else
                    >
                      <img src="../assets/logo-white.png" alt="" />
                    </div>
                  </router-link>
                  <div class="col-7 pl-2">
                    <div class="">
                      <div
                        class="subheading medium pointer"
                        @click="gotoProfile(expert.id)"
                      >
                        {{ expert.title.rendered }}
                      </div>
                      <div class="text-muted mt-1" style="font-size:13.5px">
                        <div class="_flex mb-3">
                          <i class="icon-pin mr-2" aria-hidden="true"></i>
                          <span v-if="french">{{ expert.acf.grade_fr }}</span>
                          <span v-if="english">{{ expert.acf.grade_en }}</span>
                        </div>
                        <!-- <div class="_flex">
                                            {{expert.location_1}} <span v-if="expert.location_2">• {{expert.location_2}}</span> <span v-if="expert.location_3">• {{expert.location_3}}</span>
                                          </div> -->
                        <div class="_flex mb-1" v-if="expert.acf.office.length">
                          <span
                            class="icon-location-pin mr-1"
                            aria-hidden="true"
                          ></span>
                          <span
                            v-for="office in expert.acf.office"
                            :key="office.term_id"
                            class="office--span"
                            >{{ office.name }}</span
                          >
                        </div>
                        <div class="_flex"v-if="expert.acf.office.length">
                          <i class="icon-phone mr-2" aria-hidden="true"></i>
                          <span
                            v-for="(phone, i) in getExpertPhone(
                              expert.acf.office
                            )"
                            :key="i"
                            class="office--span"
                            >{{ phone }}</span
                          >
                        </div>
                        <div class="_flex" v-if="expert.acf.email">
                          <i
                            class="icon-envelope-open mr-2"
                            aria-hidden="true"
                          ></i>
                          {{ expert.acf.email }}
                        </div>
                      </div>
                      <router-link
                        :to="`/profile/${expert.slug}`"
                        class="mt-2 _flex"
                        style="font-size:13.5px"
                      >
                        <svg
                          aria-hidden="true"
                          focusable="false"
                          width="12px"
                          height="12px"
                          fill="currentColor"
                          viewBox="0 0 36 36"
                          class="widget--hover-brand-color widget--arrow"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M18.2354 2H24.2711L36 18L24.2711 34H18.2354L28.1237 20.56H0V15.44H28.1237L18.2354 2Z"
                            fill="currentColor"
                          ></path>
                        </svg>
                        <span class="animated-underline ml-1 medium fg-primary">
                          <span v-if="french">Voir profil</span>
                          <span v-if="english">See profile</span>
                        </span>
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </slick>
          </div>
        </div>
      </div>
      <!--  -->
      <!-- <div class="col-12 col-md-6 col-lg-12 pl-md-3 pl-lg-0">
        <div class="secondary--background widget--home-experts-three p-3 pl-4">
          <h5 class="mt-2 mb-4 text-uppercase gd-sage">
            <strong v-if="french">NOS BROCHURES</strong>
            <strong v-if="english">OUR BROCHURES</strong>
          </h5>
          <div class="widget--news" v-if="brochures.length">
            <slick
              ref="slick"
              :options="slickBrochureOptions"
            >
              <div v-for="brochure in brochures" :key="brochure.id" v-if="french && brochure.language == 'french'">
                <div class="row">
                  <div class="col-5">
                    <img :data-src="brochure.cover" alt="" class="lazyload widget--brochure-img" v-if="brochure.cover">
                    <div class="widget--box-fade d-flex flex-row align-items-center justify-content-center" v-if="!brochure.cover">
                      <img src="../assets/logo-white.png" alt="">
                    </div>
                  </div>
                  <div class="col-7 pl-0">
                    <h6 class="widget--title mt-0">
                      <span>{{brochure.title}}</span>
                    </h6>
                    <div class="widget--subtitle" style="font-size:13px;">
                      <span v-html="formatText(brochure.description)"></span>
                    </div>
                    <div class="my-3">
                      <a :href="brochure.download_link" class="widget--brand-color pointer font-small">
                        <i class="far fa-arrow-to-bottom mr-2"></i>
                        <strong v-if="french">Télécharger</strong>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div v-for="brochure in brochures" :key="brochure.id" v-if="english && brochure.language == 'english'">
                <div class="row">
                  <div class="col-5">
                    <img :data-src="brochure.cover" alt="" class="lazyload widget--brochure-img" v-if="brochure.cover">
                    <div class="widget--box-fade d-flex flex-row align-items-center justify-content-center" v-if="!brochure.cover">
                      <img src="../assets/logo-white.png" alt="">
                    </div>
                  </div>
                  <div class="col-7 pl-0">
                    <h6 class="widget--title mt-0">
                      <span>{{brochure.title}}</span>
                    </h6>
                    <div class="widget--subtitle" style="font-size:13px;">
                      <span v-html="formatText(brochure.description)"></span>
                    </div>
                    <div class="my-3">
                      <a :href="brochure.download_link" class="widget--brand-color pointer font-smal">
                        <i class="far fa-arrow-to-bottom mr-2"></i>
                        <strong v-if="english">Download</strong>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </slick>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { languageMixin } from "../mixins/language";

export default {
  mixins: [languageMixin],
  data: () => ({
    slickExpertOptions: {
      arrows: false,
      dots: false,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 5000,
      adaptiveHeight: true,
    },
    slickBrochureOptions: {
      arrows: false,
      dots: false,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 3500,
      fade: true,
      adaptiveHeight: true,
    },
  }),
  computed: {
    ...mapGetters(["newExperts", "brochures"]),
  },
  methods: {
    formatText(text) {
      const length = 200;
      return text.substring(0, length) + " ...";
    },
  },
  created() {
    if (!this.newExperts.length) {
      this.$store.dispatch("getExperts");
    }
    if (!this.brochures.length) {
      this.$store.dispatch("getBrochures");
    }
  },
};
</script>

<style lang="scss" scoped>
.agile {
  &__dots {
    bottom: 0;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
  }

  &__dot {
    button {
      background-color: transparent;
      border: 1px solid #fff;

      &:hover {
        background-color: #fff;
      }
    }

    &--current {
      button {
        background-color: #fff;
      }
    }
  }

  &__arrow {
    height: 100%;
    top: 0;
    width: 80px;

    &:hover {
      background-color: rgba(#000, 0.5);

      #arrow-svg {
        fill: #fff;
      }
    }

    &[disabled] {
      display: none;
    }

    #arrow-svg {
      fill: rgba(#fff, 0.4);
      height: 25px;
    }
  }
}
</style>
